import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const CutOverview = (props) => {
  const { node } = props

  return (
    <Row key={`${node.slug}-overview`} className="py-1 py-lg-2 cut-overview">
      <Col sm="6" className="image-col d-md-flex align-items-center">
        <GatsbyImage
          image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
          alt={node.featuredImage.node.altText}
          className="w-100 rounded-5 mb-1 mb-sm-0"
          imgClassName="rounded-5"
        />
      </Col>
      <Col sm="6" className="d-flex align-items-center text-col">
        <div className="px-12 py-md-1 px-lg-2 px-xl-4">
          <h2 className="fw-bold mb-xl-2 fw-bolder">{node.title}</h2>
          <div className="subtitle mb-xl-2" dangerouslySetInnerHTML={{ __html: node.content }} />
          <div className="d-flex align-items-center mb-1 mb-sm-0 flex-wrap">
            <Link
              className="btn btn-warning btn-lg me-12 my-14"
              data-ad-element-type="cta button"
              data-ad-text="Find in Store"
              data-ad-button-id="version 1"
              data-ad-button-location="body"
              to="/where-is-meati"
            >
              Find in Store
            </Link>
            <AnchorLink
              to={`/kitchen#${node.slug}`}
              title="How to Prepare"
              className="btn btn-link text-dark my-14 px-14 mx-1 rounded-0"
              data-ad-element-type="cta button"
              data-ad-text="How to prepare"
              data-ad-button-id="version 1"
              data-ad-button-location="body"
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default CutOverview
