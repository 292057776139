import * as React from 'react'

const NutritionLineItem = (props) => {
  const lineItemSlug = props.node.item.replace(/\s+/g, '-').toLowerCase()

  // GraphQL/ACF is returning NULL instead of 0 for items with 0% daily value.
  // Let's define the items that should actually be NULL so we can add zero as needed.
  const emptyPDVItems = [
    'Trans Fat',
    'Total Sugars',
  ]

  let pdv = ''
  if (props.node.percentDailyValue) {
    pdv = `${props.node.percentDailyValue}%`
  } else if (emptyPDVItems.includes(props.node.item)) {
    pdv = null
  } else {
    pdv = '0%'
  }

  return (
    <div
      key={`panel-top-line-item-${props.index}`}
      className={`d-flex justify-content-between py-14 border-bottom line-item line-item-${lineItemSlug}`}
    >
      <div className="line-item-title">{props.node.item}</div>
      <div className="d-flex">

        {props.node.amount
          && <div className="ms-12">{props.node.amount}</div>}
        <div className="ms-1 dv-col">
          {pdv && pdv}
        </div>

      </div>

    </div>
  )
}

export default NutritionLineItem
