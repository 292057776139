import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap'
import Glide from '@glidejs/glide'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons'
import RecipeBlock from '../common/recipe-block'

const WithTitleContainer = (props) => {
  return props.sideTitle !== undefined
    ? (
      <div className="container-lg px-1 px-md-2 px-lg-0">
        <Row>
          <Col md="5" xl="4" className="offset-xl-1">
            <h3 className="side-title fw-bolder mb-2 mt-1">{props.sideTitle.replace('-', ' ').toLowerCase()}</h3>
          </Col>
          <Col md="7">
            {props.children}
          </Col>
        </Row>
      </div>
    )
    : (
      <>
        {props.children}
      </>
    )
}

const RecipeBlockContainer = (props) => {
  return props.withSlider === true
    ? (
      <div className="recipe-glide">
        <div className="glide__track" data-glide-el="track">
          <div className="glide__slides">
            {props.children}
          </div>
        </div>
        <div className="glide__arrows" data-glide-el="controls">
          <button className="glide__arrow glide__arrow--left" data-glide-dir="<" style={{ left: '20px' }}>
            <FontAwesomeIcon aria-hidden="true" className="text-dark" icon={faArrowCircleLeft} />
            <span className="visually-hidden-focusable">Previous Slide</span>
          </button>
          <button className="glide__arrow glide__arrow--right text-dark" data-glide-dir=">" style={{ right: '20px' }}>
            <FontAwesomeIcon aria-hidden="true" className="text-dark" icon={faArrowCircleRight} />
            <span className="visually-hidden-focusable">Next Slide</span>
          </button>
        </div>
      </div>
    )
    : (
      <>
        {props.children}
      </>
    )
}

const RecipeCarouselBlocks = (props) => {
  const recipes = useStaticQuery(graphql`
    query {
      allWpRecipe(
        sort: { fields: [date], order: DESC }
      ) {
        nodes {
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          slug
          recipes {
            cookTime
            prepTime
            madeWith
            serves
            shareEnabled
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    width: 300
                    placeholder: BLURRED
                    formats: NO_CHANGE
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  const isHandle = (arr, totest) => arr.filter((el) => el.recipes.madeWith[0] === totest)

  const displaySlider = isHandle(recipes.allWpRecipe.nodes, props.handle)
  const sliderItems = props.handle === 'all' ? recipes.allWpRecipe.nodes : displaySlider

  let foodSlideshow = {}

  useEffect(() => {
    foodSlideshow = new Glide('.recipe-glide', {
      type: 'slider',
      startAt: 0,
      perView: 3,
      gap: 30,
      peek: 225,
      bound: true,
      breakpoints: {
        1399: {
          perView: 3,
          peek: 100,
        },
        991: {
          gap: 20,
          peek: { before: 60, after: 60 },
          perView: 2,
        },
        575: {
          gap: 10,
          peek: { before: 60, after: 60 },
          perView: 1,
        },
      },
    })

    if (props.handle === 'all' || displaySlider.length > 3) foodSlideshow.mount()
  }, [])

  return (
    <section id="recipeBlocks" className="py-2 py-md-3 py-xl-4 bg-dark">
      <Container fluid className="recipe-blocks-wrapper g-0">
        {
          props.sectionTitle
            && (
            <Container>
              <h2 className="text-center mb-1 mb-md-2 mb-xl-3 text-white">
                {props.sectionTitle || "Our Chef's Faves"}
              </h2>
            </Container>
            )
}

        <WithTitleContainer sideTitle={props.sideTitle}>
          <Row className="recipe-blocks recipe-blocks--rainbow g-1 g-lg-2 justify-content-center text-center">
            <RecipeBlockContainer withSlider={props.handle === 'all' || displaySlider.length > 3}>
              {
                sliderItems.map((node, index) => {
                  if (props.handle === node.recipes.madeWith[0] || props.handle === 'all') {
                    return (
                      <Col className={`recipe-column ${props.sideTitle !== undefined ? 'col-6' : 'col-xxl-2 col-sm-4 col-6'}`} key={`recipe-block-${index}`}>
                        <RecipeBlock justify="center" node={node} />
                      </Col>
                    )
                  }
                  return false
                })
              }
            </RecipeBlockContainer>
          </Row>
        </WithTitleContainer>

      </Container>
    </section>
  )
}

export default RecipeCarouselBlocks
