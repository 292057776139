import React from 'react'
import { Row, Col } from 'react-bootstrap'
import NutritionLineItem from '../sections/parts/nutrition-line-item'

const NutritionPanel = ({ node }) => {
  return (
    <div className="rounded-5 nfp-bg nutrition-panel p-2">
      <div className="d-md-flex justify-content-between align-items-stretch nfp-header border-bottom border-5">
        <div className="nfp-title lh-1 me-12 fs-1 fw-bold">Nutrition Facts</div>
        <div className="d-md-flex flex-column align-items-end py-14">
          <p>{node.nutritionFacts.overview.servingsPerContainer} Servings Per Container</p>
          <p className="fw-bold">
            Serving Size: 
            <span className="ms-14">{node.nutritionFacts.overview.servingSize}</span>
          </p>
        </div>
      </div>
      <div className="nfp-calories d-flex justify-content-between align-items-end border-bottom border-3">
        <div>
          <p className="fs-3">Calories</p>
        </div>
        <div className="d-flex flex-column align-items-end">
          <p>Amount per serving</p>
          <p className="fs-2">{node.nutritionFacts.overview.calories}</p>
        </div>
      </div>
      <Row>
        <Col sm="6">
          <div className="nfp-panel-top border-bottom border-5">
            <div className="border-bottom py-14 d-flex justify-content-end">
              <p>
                Amount per serving
                <sup>*</sup>
              </p>
              <p className="ms-1 dv-col">
                % DV
                <sup>*</sup>
              </p>
            </div>
            {node.nutritionFacts.panelTop.map((node, index) => (
              <NutritionLineItem node={node} index={index} />
            ))}
          </div>
        </Col>
        <Col sm="6">
          <div className="nfp-panel-bottom border-bottom border-5">
            <p className="text-end border-bottom py-14 d-none d-sm-block">
              % DV
              <sup>*</sup>
            </p>
            {node.nutritionFacts.panelBottom.map((node, index) => (
              <NutritionLineItem node={node} index={index} />
            ))}
          </div>
        </Col>
      </Row>
      <div className="dv-disclaimer pt-12 lh-1">
        <sup>*</sup> The % Daily Value (DV) tells you how much a nutrient in a serving of food
        contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.
      </div>
    </div>
  )
}

export default NutritionPanel