import React from 'react'

const NutritionIngredientsList = ({ node, ingredients, showTitle=false }) => {
  return (
    <div className="nutrition-ingredients-list">
      { showTitle && <h3>Product ingredients</h3> }
      <div
        className="subtitle"
        dangerouslySetInnerHTML={{ __html: ingredients }}
      />
    </div>
  )
}

export default NutritionIngredientsList